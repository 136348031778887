"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMakeAndWithStyles = exports.GlobalStyles = exports.keyframes = exports.createWithStyles = exports.TssCacheProvider = exports.createMakeStyles = void 0;
const makeStyles_1 = require("./makeStyles");
Object.defineProperty(exports, "createMakeStyles", { enumerable: true, get: function () { return makeStyles_1.createMakeStyles; } });
Object.defineProperty(exports, "TssCacheProvider", { enumerable: true, get: function () { return makeStyles_1.TssCacheProvider; } });
const withStyles_1 = require("./withStyles");
Object.defineProperty(exports, "createWithStyles", { enumerable: true, get: function () { return withStyles_1.createWithStyles; } });
/** @see <https://docs.tss-react.dev/api/keyframes> */
var react_1 = require("@emotion/react");
Object.defineProperty(exports, "keyframes", { enumerable: true, get: function () { return react_1.keyframes; } });
/** @see <https://docs.tss-react.dev/api/globalstyles> */
var GlobalStyles_1 = require("./GlobalStyles");
Object.defineProperty(exports, "GlobalStyles", { enumerable: true, get: function () { return GlobalStyles_1.GlobalStyles; } });
/** @see <https://docs.tss-react.dev/api/makestyles> */
function createMakeAndWithStyles(params) {
    return Object.assign(Object.assign({}, (0, makeStyles_1.createMakeStyles)(params)), (0, withStyles_1.createWithStyles)(params));
}
exports.createMakeAndWithStyles = createMakeAndWithStyles;
