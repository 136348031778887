import {gql} from "@apollo/client";
import {CORE_CHAT_FIELDS} from "./chatQueries";
import {CORE_ACTION_FIELDS} from "./actionQueries";
import {CORE_TAG_FIELDS} from "./tagQueries";

const CORE_INCIDENT_FIELDS = gql`
  fragment CoreIncidentFields on Incident {
    id
    identifier
    customIdentifier
    name
    description
    isApproved
    isArchived
    isDeleted
    isRedacted
    state
    actionVisibility
    createdAt
    updatedAt
    closedAt
  }
`;

const INCIDENT_PHASE = gql`
  fragment IncidentPhase on IncidentPhase {
    id
    name
    description
    ordering
    backgroundColor
    foregroundColor
  }
`;

const INCIDENT_SEVERITY = gql`
  fragment IncidentSeverity on IncidentSeverity {
    id
    name
    description
    ordering
    backgroundColor
    foregroundColor
  }
`;

const INCIDENT_DATASET = gql`
  fragment IncidentDataset on IncidentDataset {
    id
    datasetId
    isDeleted
    dataset {
      id
      name
      description
      datasetAttributes {
        id
        value
        condition {
          id
          field
          fieldType
        }
      }
    }
  }
`;

const INCIDENT_ROLE = gql`
  fragment IncidentRole on IncidentRole {
    id
    roleId
    role {
      id
      name
    }
  }
`;

export const INCIDENT_TAGS = gql`
  ${CORE_TAG_FIELDS}
  fragment IncidentTags on IncidentTag {
    id
    isDeleted
    tag {
      ...CoreTagFields
    }
  }
`;

const INCIDENT_USER = gql`
  fragment IncidentUser on IncidentUser {
    id
    userId
    user {
      id
      fullName
      email
      isEnabled
    }
  }
`;

const CORE_TYPE = gql`
  fragment CoreType on Type {
    id
    name
    description
    backgroundColor
    foregroundColor
    isDeleted
  }
`;

const EXTENDED_INCIDENT_FIELDS = gql`
  ${CORE_ACTION_FIELDS}
  ${CORE_CHAT_FIELDS}
  ${CORE_INCIDENT_FIELDS}
  ${INCIDENT_PHASE}
  ${INCIDENT_SEVERITY}
  ${INCIDENT_DATASET}
  ${INCIDENT_ROLE}
  ${INCIDENT_TAGS}
  ${INCIDENT_USER}
  ${CORE_TYPE}
  fragment ExtendedIncidentFields on Incident {
    ...CoreIncidentFields
    leadUser {
      id
      email
      fullName
      nickname
      profileDocumentPreview
    }
    chatMessages {
      ...CoreChatFields
    }
    documents {
      documentId
      document {
        id
        name
        updatedAt
      }
    }
    phase {
      ...IncidentPhase
    }
    severity {
      ...IncidentSeverity
    }
    datasets {
      ...IncidentDataset
    }
    roles {
      ...IncidentRole
    }
    users {
      ...IncidentUser
    }
    tags {
      ...IncidentTags
    }
    notes {
      id
      note {
        content
      }
    }
    attributes {
      id
      conditionId
      condition {
        id
        field
        fieldType
      }
      value
    }
    types {
      id
      typeId
      type {
        ...CoreType
      }
    }
    actions(where: {isDeleted: {equals: false}}) {
      ...CoreActionFields
    }
    zoomMeeting
  }
`;

const GET_INCIDENTS_LIST = gql`
  ${CORE_INCIDENT_FIELDS}
  ${INCIDENT_PHASE}
  ${INCIDENT_SEVERITY}
  ${INCIDENT_TAGS}
  query GetIncidentsList($isArchived: Boolean) {
    incidents(where: {isArchived: {equals: $isArchived}}) {
      ...CoreIncidentFields
      phase {
        ...IncidentPhase
      }
      severity {
        ...IncidentSeverity
      }
      tags {
        ...IncidentTags
      }
    }
    state: __type(name: "State") {
      name
      enumValues {
        name
      }
    }
  }
`;

const GET_INCIDENT_METRICS = gql`
  query GetIncidentMetrics($incidentId: Int!) {
    incidentMetrics(incidentId: $incidentId)
  }
`;

const GET_INCIDENTS = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  query GetIncidents {
    incidents {
      ...ExtendedIncidentFields
    }
  }
`;

const GET_INCIDENTS_ON_LOGIN = gql`
  query GetIncidentsOnLogin {
    incidents {
      id
    }
  }
`;

const GET_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  ${CORE_TYPE}
  query GetOneIncident($id: Int!) {
    incident(where: {id: $id}) {
      ...ExtendedIncidentFields
    }
    incidentHistories(
      where: {incidentId: {equals: $id}}
      orderBy: {timestamp: desc}
      take: 5
    ) {
      id
      event
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      incident {
        id
        name
      }
    }
    actionHistories(
      where: {action: {is: {incidentId: {equals: $id}}}}
      orderBy: {timestamp: desc}
      take: 5
    ) {
      id
      event
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      action {
        name
        id
        isDeleted
        actionType
        activities {
          id
          name
        }
      }
    }
    activityHistories(
      where: {activity: {is: {action: {is: {incidentId: {equals: $id}}}}}}
      orderBy: {timestamp: desc}
      take: 5
    ) {
      id
      event
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      activity {
        id
        name
        action {
          id
          isDeleted
          actionType
          name
        }
      }
    }
    incidentPhases(orderBy: {ordering: asc}) {
      id
      ordering
      name
      description
    }
    incidentDatasets {
      id
      dataset {
        name
        description
      }
    }
    lastActionPhase: actionPhases(
      orderBy: {ordering: desc}
      where: {isDeleted: {equals: false}}
      take: 1
    ) {
      id
      ordering
      name
    }
    incidentSeverities(orderBy: {ordering: desc}) {
      id
      name
      description
      ordering
      backgroundColor
      foregroundColor
      isDeleted
    }
    types(orderBy: {name: asc}) {
      ...CoreType
    }
    state: __type(name: "State") {
      name
      enumValues {
        name
      }
    }
  }
`;

const GET_INCIDENT_DETAILED_HISTORY = gql`
  query GetIncidentDetailedHistory($id: Int!) {
    incidentHistories(
      where: {incidentId: {equals: $id}}
      orderBy: {timestamp: desc}
    ) {
      id
      event
      raw
      readableRaw
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      incident {
        id
        name
      }
    }
    actionHistories(
      where: {action: {is: {incidentId: {equals: $id}}}}
      orderBy: {timestamp: desc}
    ) {
      id
      event
      raw
      readableRaw
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      action {
        name
        id
        isDeleted
        actionType
        activities {
          id
          name
          description
        }
      }
    }
    activityHistories(
      where: {activity: {is: {action: {is: {incidentId: {equals: $id}}}}}}
      orderBy: {timestamp: desc}
    ) {
      id
      event
      raw
      readableRaw
      summary
      timestamp
      user {
        id
        email
        fullName
      }
      activity {
        id
        name
        action {
          id
          isDeleted
          actionType
          name
        }
      }
    }
  }
`;

const CREATE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation CreateIncidentWithActions(
    $name: String!
    $description: String
    $identifier: String!
    $actionVisibility: ActionVisibility
    $customIdentifier: String
    $isApproved: Boolean
    $isRedacted: Boolean
    $noteContent: String!
    $phaseId: Int!
    $severityId: Int!
    $state: State
    $types: [IncidentTypeCreateManyIncidentInput!]!
    $attributes: IncidentAttributeCreateNestedManyWithoutIncidentInput
    $datasets: IncidentDatasetCreateNestedManyWithoutIncidentInput
    $documents: IncidentDocumentCreateNestedManyWithoutIncidentInput
    $leadUser: UserCreateNestedOneWithoutLeadIncidentsInput
    $tags: IncidentTagCreateNestedManyWithoutIncidentInput
    $users: IncidentUserCreateNestedManyWithoutIncidentInput
    $actions: [CustomIncidentActionCreateInput!]
  ) {
    createIncidentWithActions(
      data: {
        name: $name
        description: $description
        identifier: $identifier
        actionVisibility: $actionVisibility
        customIdentifier: $customIdentifier
        isApproved: $isApproved
        isRedacted: $isRedacted
        notes: {create: {note: {create: {content: $noteContent}}}}
        phase: {connect: {id: $phaseId}}
        severity: {connect: {id: $severityId}}
        state: $state
        types: {createMany: {data: $types}}
        attributes: $attributes
        datasets: $datasets
        documents: $documents
        leadUser: $leadUser
        tags: $tags
        users: $users
      }
      actionsToCreate: $actions
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const GET_SEVERITIES = gql`
  ${INCIDENT_SEVERITY}
  query GetSeverities {
    incidentSeverities(orderBy: {ordering: desc}) {
      ...IncidentSeverity
    }
  }
`;

const GET_TYPES = gql`
  ${CORE_TYPE}
  query GetIncidentTypes {
    types(orderBy: {name: asc}) {
      ...CoreType
    }
  }
`;

const GET_SEVERITIES_AND_TYPES = gql`
  ${INCIDENT_SEVERITY}
  ${CORE_TYPE}
  query GetSeveritiesAndTypes {
    incidentSeverities(orderBy: {ordering: desc}) {
      ...IncidentSeverity
    }
    types(orderBy: {name: asc}) {
      ...CoreType
    }
  }
`;

const SET_INCIDENT_PHASE = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation SetIncidentPhase(
    $incidentId: Int!
    $incidentPhaseId: Int!
    $state: EnumStateFieldUpdateOperationsInput
  ) {
    updateOneIncident(
      where: {id: $incidentId}
      data: {phase: {connect: {id: $incidentPhaseId}}, state: $state}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const UPDATE_INCIDENT_PHASE = gql`
  ${CORE_INCIDENT_FIELDS}
  ${INCIDENT_PHASE}
  mutation UpdateIncidentPhase($id: Int!, $phaseId: Int!) {
    updateOneIncident(
      where: {id: $id}
      data: {phase: {connect: {id: $phaseId}}}
    ) {
      ...CoreIncidentFields
      phase {
        ...IncidentPhase
      }
    }
  }
`;

const UPDATE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation UpdateIncidentAndIfRequiredActions(
    $id: Int!
    $newIncidentName: String
    $description: NullableStringFieldUpdateOperationsInput
    $actionVisibility: NullableEnumActionVisibilityFieldUpdateOperationsInput
    $customIdentifier: NullableStringFieldUpdateOperationsInput
    $leadUser: UserUpdateOneWithoutLeadIncidentsNestedInput
    $notes: IncidentNoteUpdateManyWithoutIncidentNestedInput
    $severity: IncidentSeverityUpdateOneRequiredWithoutIncidentsNestedInput
    $attributes: IncidentAttributeUpdateManyWithoutIncidentNestedInput
    $datasets: IncidentDatasetUpdateManyWithoutIncidentNestedInput
    $documents: IncidentDocumentUpdateManyWithoutIncidentNestedInput
    $tags: IncidentTagUpdateManyWithoutIncidentNestedInput
    $types: IncidentTypeUpdateManyWithoutIncidentNestedInput
    $actions: [CustomIncidentActionCreateInput!]
  ) {
    updateIncidentWithActions(
      where: {id: $id}
      data: {
        name: {set: $newIncidentName}
        description: $description
        actionVisibility: $actionVisibility
        customIdentifier: $customIdentifier
        leadUser: $leadUser
        notes: $notes
        severity: $severity
        attributes: $attributes
        datasets: $datasets
        documents: $documents
        tags: $tags
        types: $types
      }
      actionsToCreate: $actions
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const UPDATE_INCIDENT_ACCESS = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation UpdateIncidentAccess(
    $id: Int!
    $isRedacted: Boolean!
    $roles: IncidentRoleUpdateManyWithoutIncidentNestedInput
    $users: IncidentUserUpdateManyWithoutIncidentNestedInput
  ) {
    updateOneIncident(
      where: {id: $id}
      data: {isRedacted: {set: $isRedacted}, roles: $roles, users: $users}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const UPDATE_INCIDENT_ACTIONS_ORDER = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation UpdateIncidentActionsOrder(
    $id: Int!
    $actions: ActionUpdateManyWithoutIncidentNestedInput!
  ) {
    updateOneIncident(where: {id: $id}, data: {actions: $actions}) {
      ...ExtendedIncidentFields
    }
  }
`;

const DELETE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation DeleteIncident($id: Int) {
    deleteOneIncident(where: {id: $id}) {
      ...ExtendedIncidentFields
    }
  }
`;

const ARCHIVE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation ArchiveIncident($id: Int!, $archive: Boolean!) {
    updateOneIncident(where: {id: $id}, data: {isArchived: {set: $archive}}) {
      ...ExtendedIncidentFields
    }
  }
`;

const APPROVE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation ApproveIncident(
    $id: Int!
    $isApproved: Boolean
    $state: EnumStateFieldUpdateOperationsInput
  ) {
    updateOneIncident(
      where: {id: $id}
      data: {isApproved: {set: $isApproved}, state: $state}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const CLOSE_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation CloseIncident($id: Int!) {
    closeIncident(where: {id: $id}) {
      ...ExtendedIncidentFields
    }
  }
`;

const UPDATE_INCIDENT_STATE = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation UpdateIncidentState(
    $id: Int!
    $state: State!
    $closedAt: NullableDateTimeFieldUpdateOperationsInput
  ) {
    updateOneIncident(
      where: {id: $id}
      data: {state: {set: $state}, closedAt: $closedAt}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const GET_INCIDENT_STATES = gql`
  query GetStates {
    state: __type(name: "State") {
      name
      enumValues {
        name
      }
    }
  }
`;

const GET_RELATED_INCIDENTS = gql`
  ${CORE_INCIDENT_FIELDS}
  ${INCIDENT_TAGS}
  ${CORE_TYPE}
  query GetRelatedIncidents(
    $excludeIncidentId: Int!
    $severityIds: [Int!]!
    $typeIds: [Int!]!
  ) {
    incidents(
      where: {
        AND: [
          {
            id: {not: {equals: $excludeIncidentId}}
            severity: {is: {id: {in: $severityIds}}}
            types: {some: {typeId: {in: $typeIds}}}
          }
        ]
      }
    ) {
      ...CoreIncidentFields
      tags {
        ...IncidentTags
      }
      types {
        type {
          ...CoreType
        }
      }
    }
  }
`;

const ADD_INCIDENT_FILES = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation AddIncidentDocuments(
    $id: Int!
    $documentsToCreate: [IncidentDocumentCreateManyIncidentInput!]!
  ) {
    updateOneIncident(
      where: {id: $id}
      data: {documents: {createMany: {data: $documentsToCreate}}}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const DELETE_INCIDENT_FILES = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation DeleteIncidentDocuments($id: Int!, $documentsToDelete: [Int!]) {
    updateOneIncident(
      where: {id: $id}
      data: {documents: {deleteMany: {documentId: {in: $documentsToDelete}}}}
    ) {
      ...ExtendedIncidentFields
    }
  }
`;

const CREATE_MESSAGE = gql`
  ${CORE_CHAT_FIELDS}
  mutation CreateIncidentMessage($id: Int!, $message: String!, $userId: Int!) {
    updateOneIncident(
      where: {id: $id}
      data: {
        chatMessages: {
          create: {message: $message, user: {connect: {id: $userId}}}
        }
      }
    ) {
      id
      chatMessages {
        ...CoreChatFields
      }
    }
  }
`;

const UPSERT_ZOOM_MEETING_FOR_INCIDENT = gql`
  ${EXTENDED_INCIDENT_FIELDS}
  mutation UpsertZoomMeetingForIncident($incidentId: Int!) {
    upsertZoomMeetingForIncident(where: {id: $incidentId}) {
      ...ExtendedIncidentFields
    }
  }
`;

export {
  CORE_INCIDENT_FIELDS,
  ARCHIVE_INCIDENT,
  CREATE_INCIDENT,
  UPDATE_INCIDENT,
  CLOSE_INCIDENT,
  DELETE_INCIDENT,
  GET_INCIDENT,
  GET_INCIDENT_DETAILED_HISTORY,
  GET_INCIDENTS,
  GET_INCIDENTS_LIST,
  GET_INCIDENT_METRICS,
  GET_INCIDENTS_ON_LOGIN,
  GET_INCIDENT_STATES,
  GET_SEVERITIES,
  GET_TYPES,
  GET_SEVERITIES_AND_TYPES,
  SET_INCIDENT_PHASE,
  UPDATE_INCIDENT_PHASE,
  UPDATE_INCIDENT_STATE,
  UPDATE_INCIDENT_ACCESS,
  UPDATE_INCIDENT_ACTIONS_ORDER,
  APPROVE_INCIDENT,
  GET_RELATED_INCIDENTS,
  ADD_INCIDENT_FILES,
  DELETE_INCIDENT_FILES,
  CREATE_MESSAGE,
  CORE_CHAT_FIELDS,
  UPSERT_ZOOM_MEETING_FOR_INCIDENT,
};
