var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, createElement } from "react";
import { createMakeStyles } from "./makeStyles";
import { capitalize } from "./tools/capitalize";
export function createWithStyles(params) {
    const { useTheme, cache } = params;
    const { makeStyles } = createMakeStyles({ useTheme, cache });
    function withStyles(Component, cssObjectByRuleNameOrGetCssObjectByRuleName, params) {
        const Component_ = typeof Component === "string"
            ? (() => {
                const tag = Component;
                const Out = function (_a) {
                    var { children } = _a, props = __rest(_a, ["children"]);
                    return createElement(tag, props, children);
                };
                Object.defineProperty(Out, "name", {
                    "value": capitalize(tag),
                });
                return Out;
            })()
            : Component;
        /**
         * Get component name for wrapping
         * @see https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
         */
        const name = (() => {
            {
                const { name: nameOrWrappedName } = params !== null && params !== void 0 ? params : {};
                if (nameOrWrappedName !== undefined) {
                    return typeof nameOrWrappedName !== "object"
                        ? nameOrWrappedName
                        : Object.keys(nameOrWrappedName)[0];
                }
            }
            {
                const displayName = Component_.displayName;
                if (typeof displayName === "string" && displayName !== "") {
                    return displayName;
                }
            }
            {
                const { name } = Component_;
                if (name) {
                    return name;
                }
            }
        })();
        const useStyles = makeStyles(Object.assign(Object.assign({}, params), { name }))(typeof cssObjectByRuleNameOrGetCssObjectByRuleName === "function"
            ? (theme, props, classes) => incorporateMediaQueries(cssObjectByRuleNameOrGetCssObjectByRuleName(theme, props, classes))
            : incorporateMediaQueries(cssObjectByRuleNameOrGetCssObjectByRuleName));
        function getHasNonRootClasses(classes) {
            for (const name in classes) {
                if (name === "root") {
                    continue;
                }
                return true;
            }
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Out = forwardRef(function (props, ref) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { className, classes: _classes } = props, rest = __rest(props, ["className", "classes"]);
            const { classes, cx } = useStyles(props, { props });
            return (React.createElement(Component_, Object.assign({ ref: ref, className: getHasNonRootClasses(classes)
                    ? className
                    : cx(classes.root, className) }, (typeof Component === "string" ? {} : { classes }), rest)));
        });
        if (name !== undefined) {
            Out.displayName = `${capitalize(name)}WithStyles`;
            Object.defineProperty(Out, "name", { "value": Out.displayName });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Out;
    }
    return { withStyles };
}
function incorporateMediaQueries(cssObjectByRuleNameWithMediaQueries) {
    const cssObjectByRuleName = {};
    const cssObjectByRuleNameWithMediaQueriesByMediaQuery = {};
    Object.keys(cssObjectByRuleNameWithMediaQueries).forEach(ruleNameOrMediaQuery => ((ruleNameOrMediaQuery.startsWith("@media")
        ? cssObjectByRuleNameWithMediaQueriesByMediaQuery
        : cssObjectByRuleName)[ruleNameOrMediaQuery] =
        cssObjectByRuleNameWithMediaQueries[ruleNameOrMediaQuery]));
    Object.keys(cssObjectByRuleNameWithMediaQueriesByMediaQuery).forEach(mediaQuery => {
        const cssObjectByRuleNameBis = cssObjectByRuleNameWithMediaQueriesByMediaQuery[mediaQuery];
        Object.keys(cssObjectByRuleNameBis).forEach(ruleName => {
            var _a;
            return (cssObjectByRuleName[ruleName] = Object.assign(Object.assign({}, ((_a = cssObjectByRuleName[ruleName]) !== null && _a !== void 0 ? _a : {})), { [mediaQuery]: cssObjectByRuleNameBis[ruleName] }));
        });
    });
    return cssObjectByRuleName;
}
